import './styles.scss';

import ErrorPageImage from '../assets/svg/404Image';
import Footer from '../components/footer';
import Header from '../components/header/header';
import React from 'react';

export default function ErrrorPage() {
  return (
    <div className="outer-wrapper">
      <Header show={true} />
      <div className="errorPageContainer">
        <section className="errorPageImageContainer">
          <ErrorPageImage className="image" />
        </section>
        <section className="404message">
          <h5 className="messageTitle404">Oops ! Page not found</h5>
          <h6 className="messageText404">We don’t seem to find the page you are looking for.</h6>
        </section>
        <section>
          <div className="pt-4 hide-mobile">
            <a href="/" className="bg-prime fnt-white fnt-evolventa bold pl-3 pr-3 pt-2 pb-2 border-none">
              Back to homepage
            </a>
          </div>
          <div className="pt-4 hide-desktop">
            <a href="/" className="bg-prime fnt-white fnt-evolventa bold pl-3 pr-3 pt-2 pb-2 border-none">
              Back to homepage
            </a>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
